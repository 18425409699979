<template>
  <v-container class="dashboard" fluid>
    <v-row>
      <v-col cols="12" md="4">
        <DatePickerInput label="DATE"
                         dense
                         hide-details
                         v-model="selectedDate"
                         @click:append="onDatePickerAppendClicked"></DatePickerInput>
      </v-col>
      <v-col cols="12" md="4">
        <v-select outlined
                  dense
                  hide-details
                  :items="locationFilterOptions"
                  v-model="locationFilter"
                  label="LOCATION & FLOORS"
                  @input="onLocationFilterChange">
          <template #item="{item}">
            <div :class="{'item-options': true, 'region-options': !item.value.item_id, 'location-options': !item.value.item_id && !item.value.region_id}">{{ item.text }}</div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-select outlined
                  dense
                  hide-details
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  :items="itemTypeFilterOptions"
                  v-model="itemTypeFilter"
                  label="FACILITY TYPE"
                  @input="onItemTypeFilterChange">
          <!--          <template #item="{item}">-->
          <!--            <div :class="{'item-options': true, 'region-options': !item.value.item_id, 'location-options': !item.value.item_id && !item.value.region_id}">{{ item.text }}</div>-->
          <!--          </template>-->
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet height="calc(100vh - 192px)">
          <v-calendar type="category"
                      :interval-height="64"
                      :interval-width="45"
                      :start="calendarDate"
                      :categories="categories"
                      :events="processedSessions"
                      :event-color="getSessionColor"
                      :first-interval="firstInterval"
                      :interval-count="intervalCount"
                      category-show-all
                      @click:event="sessionClicked"
                      @click:date="onDatePickerAppendClicked"
                      ref="calendar">
            <template v-slot:category="{category}">
              <v-container class="text-center" @click="onDatePickerAppendClicked">{{ items[category].name }}</v-container>
            </template>
            <template v-slot:event="{}">

            </template>
          </v-calendar>
        </v-sheet>

        <v-menu v-model="sessionMenuOpened"
                max-width="480px"
                offset-x
                :close-on-content-click="false"
                :activator="selectedSessionTarget">
          <v-card outlined v-if="selectedSession">
            <v-card-title>{{ items[selectedSession.item_id].name }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field readonly
                                :label="$t('booking.account')"
                                v-if="selectedSessionBooking"
                                :value="selectedSessionBooking.account.name">
                  </v-text-field>
                  <v-text-field readonly
                                :label="$t('booking.item')"
                                :value="items[selectedSessionBooking.item].name"></v-text-field>
                  <v-text-field readonly
                                :label="$t('booking.datetime')"
                                :value="selectedSessionBookingDateTimeLabel"></v-text-field>
                  <v-text-field readonly
                                :label="$t('booking.checkin_time')"
                                v-if="selectedSessionBooking.checkin_time"
                                :value="$parseDate(selectedSessionBooking.checkin_time)"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue';
import $ from 'jquery';
import {computed, onMounted, onUnmounted, ref, watch} from "@vue/composition-api";
import {Item, ItemType, Location, Region, Session} from "@/store/models";
import DatePickerInput from "@/components/DatePickerInput";
import {DateTime} from 'luxon';
import _ from 'lodash';
import {ACTION_TYPES} from "@/store/types";
import {SESSION_STATUS} from "@/constants";

export default {
  name: 'Dashboard',
  components: {DatePickerInput},
  setup(props, {root}) {
    const selectedDate = ref(DateTime.fromJSDate(new Date()).toISODate());
    watch(() => selectedDate.value, async () => {
      await load();
    })

    const locationFilterOptions = computed(() => {
      let options = [{text: 'ALL', value: {location_id: null, region_id: null, item_id: null}}];

      // Locations
      // options.push({header: root.$t('location.title')});
      let locations = Location.query().withAllRecursive().orderBy('name').get();

      locations.forEach((l) => {
        options.push({text: l.name, value: {location_id: l.id, region_id: null, item_id: null}});

        l.regions.forEach((r) => {
          options.push({text: r.name, value: {location_id: l.id, region_id: r.id, item_id: null}});

          // r.items.forEach((i) => {
          //   options.push({text: i.name, value: {location_id: l.id, region_id: r.id, item_id: i.id}});
          // })
        })
      });
      return options;
    });
    const locationFilter = ref({location_id: null, region_id: null, item_id: null});
    const onLocationFilterChange = async function (v) {
      locationFilter.value = v;

      await load();
    };

    const itemTypeFilterOptions = computed(() => {
      let options = [];

      // Item Type
      // options.push({header: root.$t('item_type.title')});
      ItemType.all().forEach((t) => {
        options.push({text: t.name, value: t.id});
      })
      return options;
    });
    const itemTypeFilter = ref([]);
    const onItemTypeFilterChange = async function (v) {
      itemTypeFilter.value = v;
      await load();
    };

    const calendarDate = computed(() => {
      return DateTime.fromISO(selectedDate.value).toISODate();
    });
    const items = computed(() => {
      let filter = locationFilter.value;

      let items = [];

      let query = Item.query().withAllRecursive();

      if (itemTypeFilter.value.length > 0) {
        query = query.where((i) => {
          return itemTypeFilter.value.indexOf(i.type_id) !== -1;
        });
      }

      if (filter.item_id) {
        items = query.where('id', filter.item_id).get();
      } else if (filter.region_id) {
        items = query.where('region_id', filter.region_id).get();
      } else if (filter.location_id) {
        let regions = Region.query().withAllRecursive().where('location_id', filter.location_id).orderBy('name').all();

        regions.forEach((r) => {
          let query = Item.query().withAllRecursive();

          if (itemTypeFilter.value.length > 0) {
            query = query.where((i) => {
              return itemTypeFilter.value.indexOf(i.type_id) !== -1;
            });
          }

          items = items.concat(query.where('region_id', r.id).orderBy('name').all());
        });
      } else {
        let regions = Region.query().withAllRecursive().orderBy('name').all();

        regions.forEach((r) => {
          let query = Item.query().withAllRecursive();

          if (itemTypeFilter.value.length > 0) {
            query = query.where((i) => {
              return itemTypeFilter.value.indexOf(i.type_id) !== -1;
            });
          }

          items = items.concat(query.where('region_id', r.id).orderBy('name').all());
        });
      }

      let map = {};

      items.forEach((i) => {
        map[i.id] = i;
      });

      return map;
    })
    const categories = computed(() => {
      return Object.keys(items.value);
    })
    const processedSessions = computed(() => {
      let sessions = Session.all();

      return sessions.map((s) => {
        return {
          ...s,
          name: '',
          category: s.item_id,
          start: DateTime.fromISO(s.start).toFormat('yyyy-MM-dd HH:mm'),
          end: DateTime.fromISO(s.end).toFormat('yyyy-MM-dd HH:mm'),
        }
      })
    });
    const getSessionColor = function (s) {
      return {
        [SESSION_STATUS.AVAILABLE]: '#b9e0da',
        [SESSION_STATUS.OCCUPIED]: 'rgba(128, 128, 128, 0.5)',
        [SESSION_STATUS.EXCLUDED]: 'rgba(128, 128, 128, 0.2)',
        [SESSION_STATUS.EXPIRED]: 'rgba(128, 128, 128, 0.2)',
      }[s.status];
    };
    const firstInterval = computed(() => {
      let minStart = _.min(processedSessions.value.map((s) => {
        let start = DateTime.fromFormat(s.start, 'yyyy-MM-dd HH:mm');
        return (start.toSeconds() + start.offset * 60) % (24 * 3600);
      })) || 0;

      return Math.floor(minStart / 3600) - 1;
    });
    const intervalCount = computed(() => {
      let a = processedSessions.value.map((s) => {
        let start = DateTime.fromFormat(s.start, 'yyyy-MM-dd HH:mm');
        let end = DateTime.fromFormat(s.end, 'yyyy-MM-dd HH:mm');

        if (start.hasSame(end, 'day')) {
          return Math.ceil((end.toSeconds() + end.offset * 60) % (24 * 3600) / 3600);
        } else {
          return 24;
        }
      });

      return (_.max(a) || 24) - firstInterval.value + 1;
    });
    const bookings = ref([]);
    const bookingMap = computed(() => {
      let map = {};
      bookings.value.forEach((i) => {
        map[i.id] = i;
      });
      return map;
    });

    const sessionMenuOpened = ref(false);
    const selectedSession = ref(null);
    const selectedSessionTarget = ref(null);
    const selectedSessionBooking = computed(() => {
      if (selectedSession.value) {
        return bookingMap.value[selectedSession.value.booking_id];
      }
      return null;
    })
    const selectedSessionBookingDateTimeLabel = computed(() => {
      if (selectedSessionBooking.value) {
        let start = DateTime.fromISO(selectedSessionBooking.value.start_time);
        let end = DateTime.fromISO(selectedSessionBooking.value.end_time);

        if (start.hasSame(end, 'day')) {
          return `${start.toFormat('yyyy-MM-dd HH:mm')} - ${end.toFormat('HH:mm')}`;
        } else {
          return `${start.toFormat('yyyy-MM-dd HH:mm')} - ${end.toFormat('yyyy-MM-dd HH:mm')}`;
        }
      }
    })
    const sessionClicked = function ({event, nativeEvent}) {
      const open = () => {
        if (event.status !== SESSION_STATUS.AVAILABLE) {
          selectedSession.value = event;
          selectedSessionTarget.value = nativeEvent.target;
          setTimeout(() => sessionMenuOpened.value = true, 10);
        }
      }

      if (sessionMenuOpened.value) {
        sessionMenuOpened.value = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    };

    const load = async function () {
      let headers = $('.v-calendar-category__columns');
      headers.scrollLeft(0);
      
      let response = await root.$store.dispatch(ACTION_TYPES.GET_SESSIONS, {
        location_id: locationFilter.value.location_id,
        region_id: locationFilter.value.region_id,
        item_id: locationFilter.value.item_id,
        item_type_ids: itemTypeFilter.value,
        from_date: DateTime.fromISO(selectedDate.value).startOf('day').toISO(),
        to_date: DateTime.fromISO(selectedDate.value).endOf('day').toISO(),
      });
      bookings.value = response.body.bookings;

      Vue.nextTick(() => {
        let headers = $('.v-calendar-category__columns');

        headers.scroll((e) => {
          headers.scrollLeft(e.target.scrollLeft);
        })
      })
    };

    const autoRefreshInterval = ref(null);
    const datePickerAppendClickCount = ref(0);
    const onDatePickerAppendClicked = async function () {
      if (autoRefreshInterval.value) {
        if (await root.$dialog.confirm({text: 'Stop Auto-refresh?'})) {
          clearInterval(autoRefreshInterval.value);
          autoRefreshInterval.value = null;
        }
      } else {
        datePickerAppendClickCount.value = datePickerAppendClickCount.value + 1;

        if (datePickerAppendClickCount.value >= 10) {
          if (await root.$dialog.confirm({text: 'Start Auto-refresh?'})) {
            autoRefreshInterval.value = setInterval(() => {
              selectedDate.value = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
              load();
            }, 30000);

            datePickerAppendClickCount.value = 0;
          }
        }
      }


    };

    onMounted(async () => {
      await load();
    })

    onUnmounted(() => {
      clearInterval(autoRefreshInterval.value);
    })

    return {
      selectedDate,
      locationFilterOptions,
      locationFilter,
      onLocationFilterChange,

      itemTypeFilterOptions,
      itemTypeFilter,
      onItemTypeFilterChange,

      items,

      calendarDate,
      categories,
      processedSessions,
      getSessionColor,
      firstInterval,
      intervalCount,

      sessionMenuOpened,
      selectedSession,
      selectedSessionTarget,
      selectedSessionBooking,
      selectedSessionBookingDateTimeLabel,
      sessionClicked,

      bookings,
      bookingMap,

      onDatePickerAppendClicked,
    }
  }
}
</script>

<style lang="less">
.dashboard {
  .v-calendar-daily_head-weekday, .v-calendar-daily_head-day-label {
    display: none;
  }

  .v-calendar .v-event-timed-container {
    margin-right: 0 !important;
  }

  .v-calendar-category .v-calendar-category__columns {
    overflow-x: auto;

    .v-calendar-category__column {
      min-width: 150px;
    }
  }

  .v-calendar-daily_head-day .v-calendar-category__columns .v-calendar-category__column-header {
    min-width: 150px;
  }
}
</style>
